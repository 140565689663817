import React from 'react';
import styled from 'styled-components/native';
import {
  variant,
  space,
  color,
  layout,
  flexbox,
  border,
  position,
} from 'styled-system';
import { Image } from 'react-native';
import View from '../../basic/View/View';
import Text from '../../primitive/Typography/Text/Text';
import { imageTransformation } from '../../../../utilities/helper';
import useAuthStore from '../../../../stores/authStore';
import { ThemeVariables } from '../../../../themes/new/helper';
import PointsBalance from '../../custom/PointsBalance';

// export const variantsKey = { 1: 1, 2: 2, 3: 3 };
export const variantsKey = {
  0: {
    width: 32,
    height: 32,
  },
  1: {
    width: 40,
    height: 40,
  },
  2: {
    width: 60,
    height: 60,
  },
  3: {
    width: 100,
    height: 100,
  },
  4: {
    width: 72,
    height: 72,
  },
};

const sizeVariants = (props) => {
  return variant({
    prop: ThemeVariables.LEVEL,
    variants: {
      0: variantsKey[0],
      1: variantsKey[1],
      2: variantsKey[2],
      3: variantsKey[3],
      4: variantsKey[4],
    },
  });
};

const appearanceVariants = (props) => {
  return variant({
    prop: ThemeVariables.APPEARANCE,
    variants: {
      gold: {
        borderColor: props.theme.colors.static.gold,
      },
      default: {
        borderColor: 'transparent',
      },
    },
  });
};

const StyledView = styled(View)`
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  border-radius: 80;
  ${(props) => sizeVariants(props)};
`;

const StyledTextView = styled(View)`
  ${layout}
  ${space}
  ${color}
  ${flexbox}
  ${border}
  ${position}
  border-radius: 999;
  align-items: center;
  justify-content: center;
  ${(props) => sizeVariants(props)};
`;

const StyledImage = styled(Image)`
  border-width: ${({ borderWidth, theme }) => theme.borderWidths[borderWidth]};
  border-radius: 80;
  ${(props) => sizeVariants(props)};
  ${(props) => appearanceVariants(props)};
`;

const getInitials = (text) => {
  const fullName = text.trim().split(' ');
  let initials = fullName[0].charAt(0);
  if (fullName.length > 1) {
    initials += fullName[fullName.length - 1].charAt(0);
  } else {
    initials += fullName[0].charAt(1);
  }
  return initials.toUpperCase();
};

type AvatarProps = Record<string, any> & {
  level?: number;
  resizeMode?: string;
  name: string;
  pointBalanceLevel?: number | null;
  borderWidth?: string;
  appearance?: string;
};

const Avatar = ({
  level = 1,
  resizeMode = 'contain',
  name,
  pointBalanceLevel = null,
  borderWidth = 'xs',
  appearance = 'default',
  ...props
}: AvatarProps) => {
  let { source } = props;
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  if (!isLoggedIn && !source) {
    source = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  } else if (!name && !source) {
    source = 'https://ik.imagekit.io/32ndhub/assets/nouser.png';
  }

  return (
    <>
      {!source && name ? (
        <StyledTextView
          level={level}
          bg="background.secondary.base"
          borderWidth="xs"
          borderColor="primary.50"
          {...props}
        >
          <Text size={level > 1 ? `${level}xl` : 'sm'}>
            {getInitials(name)}
          </Text>
        </StyledTextView>
      ) : (
        <StyledView level={level} {...props}>
          <StyledImage
            level={level}
            style={{ resizeMode }}
            borderWidth={borderWidth}
            appearance={appearance}
            source={{
              uri: imageTransformation(source, variantsKey[level].width),
            }}
          />
        </StyledView>
      )}
      {pointBalanceLevel === 0 || pointBalanceLevel === 1 ? (
        <View position="absolute" left={0} right={0} bottom={-4}>
          <PointsBalance level={0} />
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export default Avatar;
