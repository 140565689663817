/* eslint-disable no-restricted-syntax */
import _, { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { PermissionStatus } from 'expo-modules-core/src/PermissionsInterface';
import useAuthStore from '../stores/authStore';
import useNotificationModalStore from '../stores/notificationModalStore';
import { mapTokenToUser } from '../relay/notificationTokenMappingApis';
import AppConstants from './AppConstants';

// eslint-disable-next-line import/prefer-default-export
export function isValueNullOrEmpty(value) {
  let isValue = false;
  if (value && value.toString) {
    value = value.toString();
  }
  if (
    value == null ||
    value === '' ||
    value === '.' ||
    value === 'null' ||
    value === undefined ||
    value === '0' ||
    value?.length === 0
  ) {
    isValue = true;
  }
  return isValue;
}

export function convertValuesToStringsDeep(obj) {
  const res = _.cloneDeepWith(obj, (value) =>
    !_.isPlainObject(value) ? _.toString(value) : undefined,
  );
  return res;
}

export function toCurrency(text) {
  if (text === undefined || text == null) {
    return text;
  }

  text = parseInt(text, 10);
  const x = text.toString();
  if ((x.length < 4 && text > 0) || (x.length < 5 && text < 0)) {
    return x;
  }
  let lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers !== '') lastThree = `,${lastThree}`;
  const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
  return res;
}

export function getStyledPremiumSingleFixed(prm, decPos) {
  let calculatedStyledPremium = '';
  if (prm > 0 && prm >= 1000000) {
    const x = prm / 1000000;
    const y = prm % 1000000;
    calculatedStyledPremium = `₹${toCurrency(
      parseFloat(x).toFixed(y > 0 ? decPos || 1 : 0),
    )}M`;
  } else if (prm > 0 && prm >= 100000) {
    const x = prm / 1000;
    const y = prm % 1000;
    calculatedStyledPremium = `₹${toCurrency(
      parseFloat(x).toFixed(y > 0 ? decPos || 1 : 0),
    )}K`;
  } else if (prm > 0 && prm >= 1) {
    const x = prm;
    calculatedStyledPremium = `₹${toCurrency(parseFloat(x).toFixed(0))}`;
  } else {
    calculatedStyledPremium = `₹${0}`;
  }
  return calculatedStyledPremium;
}

export function getStyledPremium(prm, short = true) {
  let calculatedStyledPremium = '';
  if (prm > 0 && prm >= 10000000) {
    const x = prm / 10000000;
    const y = prm % 10000000;
    // if (x > 5000) x = 5000;
    calculatedStyledPremium = `₹${parseFloat(x)} ${short ? 'Cr' : 'crore'}`;
  } else if (prm > 0 && prm >= 100000) {
    const x = prm / 100000;
    const y = prm % 100000;
    calculatedStyledPremium = `₹${parseFloat(x).toFixed(y > 0 ? 1 : 0)} ${
      short ? 'L' : 'lakhs'
    }`;
  } else if (prm > 0 && prm >= 1000) {
    const x = prm / 1000;
    const y = prm % 1000;
    calculatedStyledPremium = `₹${parseFloat(x).toFixed(y > 0 ? 1 : 0)} ${
      short ? 'K' : 'thousand'
    }`;
  } else if (prm > 0 && prm >= 1) {
    const x = prm;
    calculatedStyledPremium = `₹${parseFloat(x).toFixed(0)}`;
  } else {
    calculatedStyledPremium = `₹${0}`;
  }
  return calculatedStyledPremium;
}

export function convertToTime(e) {
  const m = Math.floor((e % 3600) / 60)
    .toString()
    .padStart(2, '0');
  const s = Math.floor(e % 60)
    .toString()
    .padStart(2, '0');

  return `${m}:${s}`;
}

export function shortenNumber(num: number): string {
  if (num < 1000) {
    return num.toString();
  }
  if (num < 1000000) {
    return `${(num / 1000).toFixed(1)}k`;
  }
  return `${(num / 1000000).toFixed(1)}M`;
}

function insertTodaysOrYesterdayPhoto(photos, count, finalArray, title) {
  for (const item of photos) {
    if (count === 4) break;
    finalArray.push({
      title: count === 3 ? 'See All' : title,
      photo:
        item?.assetType === 'VIDEO'
          ? `${AppConstants.IMAGEKIT_PROD_URI}videoMomentsThumbnail/${item?.thumbnailId}`
          : `${AppConstants.IMAGEKIT_PROD_URI}photoMoment/${item?.assetId}`,
      assetType: item?.assetType === 'VIDEO' ? 'VIDEO' : 'IMAGE',
    });
    count += 1;
  }
  return count;
}

function insertEachDayLatestPhoto(
  data,
  count,
  finalArray,
  skipTodayAndYesterday = false,
) {
  for (const item of Object.keys(data)) {
    if (skipTodayAndYesterday) {
      if (item === 'today' || item === 'yesterday') continue;
    }
    if (count === 4) break;

    if (data[item]?.length > 0) {
      finalArray.push({
        title: count === 3 ? 'See All' : item,
        photo:
          data[item]?.[0]?.assetType === 'VIDEO'
            ? `${AppConstants.IMAGEKIT_PROD_URI}videoMomentsThumbnail/${data[item]?.[0]?.thumbnailId}`
            : `${AppConstants.IMAGEKIT_PROD_URI}photoMoment/${data[item]?.[0]?.assetId}`,
        assetType: data[item]?.[0]?.assetType === 'VIDEO' ? 'VIDEO' : 'IMAGE',
      });
      count += 1;
    }
  }
  return count;
}

function prepareFinalArray(data) {
  const finalArray = [];
  let count = 0;
  const uniqueDates = Object.keys(data);
  if (data?.today?.length > 0) {
    if (uniqueDates?.length < 4) {
      // showing images sorted by timestamp (T,T,T,Y)
      count = insertTodaysOrYesterdayPhoto(
        data?.today,
        count,
        finalArray,
        'Today',
      );

      if (data?.yesterday?.length > 0 && count < 4) {
        count = insertTodaysOrYesterdayPhoto(
          data?.yesterday,
          count,
          finalArray,
          'Yesterday',
        );
      }

      if (count < 4) {
        for (const item of Object.keys(data)) {
          if (item === 'today' || item === 'yesterday') continue;
          for (const photo of data[item]) {
            if (count === 4) break;

            finalArray.push({
              title: count === 3 ? 'See All' : item,
              photo:
                photo?.assetType === 'VIDEO'
                  ? `${AppConstants.IMAGEKIT_PROD_URI}videoMomentsThumbnail/${photo?.thumbnailId}`
                  : `${AppConstants.IMAGEKIT_PROD_URI}photoMoment/${photo?.assetId}`,
              assetType: photo?.assetType === 'VIDEO' ? 'VIDEO' : 'IMAGE',
            });
            count += 1;
          }
        }
      }
    } else {
      count = insertEachDayLatestPhoto(data, count, finalArray, false);
    }
  } else {
    finalArray.push({
      title: 'Get Clicked',
    });
    count += 1;

    if (uniqueDates?.length < 3) {
      if (data?.yesterday?.length > 0 && count < 4) {
        count = insertTodaysOrYesterdayPhoto(
          data?.yesterday,
          count,
          finalArray,
          'Yesterday',
        );
      }

      if (count < 4) {
        for (const item of Object.keys(data)) {
          if (item === 'today' || item === 'yesterday') continue;
          for (const photo of data[item]) {
            if (count === 4) break;

            finalArray.push({
              title: count === 3 ? 'See All' : item,
              photo:
                photo?.assetType === 'VIDEO'
                  ? `${AppConstants.IMAGEKIT_PROD_URI}videoMomentsThumbnail/${photo?.thumbnailId}`
                  : `${AppConstants.IMAGEKIT_PROD_URI}photoMoment/${photo?.assetId}`,
              assetType: photo?.assetType === 'VIDEO' ? 'VIDEO' : 'IMAGE',
            });
            count += 1;
          }
        }
      }
    } else {
      count = insertEachDayLatestPhoto(data, count, finalArray, false);
    }
  }

  return finalArray;
}

export function groupPhotosOnDate(photos) {
  photos = orderBy(photos, ['clickedOn'], ['desc']);
  if (isEmpty(photos)) return {};
  // Group data by date
  const groupedData = photos.reduce((acc, item) => {
    const clickedOnDate = moment(item.clickedOn).format('DD MMM, YYYY');
    if (!acc[clickedOnDate]) {
      acc[clickedOnDate] = [];
    }
    acc[clickedOnDate].push(item);
    return acc;
  }, {});

  // sort each date photos
  const sortedGroupedData = Object.keys(groupedData).reduce((acc, date) => {
    acc[date] = orderBy(groupedData[date], ['clickedOn'], ['desc']);
    return acc;
  }, {});

  // Get today's date
  const today = moment().format('DD MMM, YYYY');

  // Get yesterday's date
  const yesterday = moment().subtract(1, 'days').format('DD MMM, YYYY');

  // Create response object
  const response = {};
  if (groupedData[today]?.length > 0) {
    response.today = groupedData[today];
  }
  if (groupedData[yesterday]?.length > 0) {
    response.yesterday = groupedData[yesterday];
  }

  const header =
    response?.today?.length > 0
      ? `${response?.today?.length} new ${
          response?.today?.length > 1 ? 'moments' : 'moment'
        } found!`
      : 'Your 32nd moments!';

  // Add other dates
  Object.keys(sortedGroupedData).forEach((date) => {
    if (date !== today && date !== yesterday) {
      response[date] = sortedGroupedData[date];
    }
  });

  const finalResultedArray = prepareFinalArray(response);

  return {
    groupPhotoMomentsOnDate: finalResultedArray,
    photoMomentsWidgetHeader: header,
  };
}

export const showNotificationPopupLogic = () => {
  const isLoggedIn = useAuthStore?.getState()?.isLoggedIn;
  const componentState = useNotificationModalStore?.getState();

  if (isLoggedIn && Platform.OS !== 'web') {
    componentState.setWillOpen(true);
    Notifications.getPermissionsAsync()
      .then((res) => res.status)
      .then((status) => {
        if (status === PermissionStatus.GRANTED) {
          // map token to new user
          Notifications.getExpoPushTokenAsync({
            projectId: Constants.expoConfig.extra.eas.projectId,
          })
            .then((res) => {
              const token = res.data;
              componentState.setWillOpen(false);
              // call api to add token
              componentState.setExpoToken(token);
              return mapTokenToUser(token);
            })
            .then((res) => {})
            .catch((err) => {
              componentState.setWillOpen(false);
              console.log(err);
            });
        } else if (Platform.OS === 'ios') {
          if (status === PermissionStatus.UNDETERMINED) {
            setTimeout(() => {
              componentState.setIsOpen(true);
            }, 1 * 1000);
          } else {
            componentState.setWillOpen(false);
          }
        } else if (Platform.OS === 'android') {
          setTimeout(() => {
            componentState.setIsOpen(true);
          }, 1 * 1000);
        }
      });
  }
};

export const getBaseUrlForDeepLink = (): string => {
  const webProductionBaseUrl = 'https://club.32nd.com/';
  const webStagingBaseUrl = 'https://staging.club.32nd.com/';
  const appProductionBaseUrl = 'club.32nd://';
  const appStagingBaseUrl = 'staging.club.32nd://';

  if (Platform.OS === 'web') {
    return process.env.EXPO_PUBLIC_APP_ENV === 'production'
      ? webProductionBaseUrl
      : webStagingBaseUrl;
  }
  return process.env.EXPO_PUBLIC_APP_ENV === 'production'
    ? appProductionBaseUrl
    : appStagingBaseUrl;
};

export const isNullOrUndefined = (value: any): boolean => {
  if (typeof value === 'undefined' || value === null) return true;
  return false;
};

export const isProperNumber = (value: any): boolean => {
  if (isNullOrUndefined(value)) return false;
  if (typeof value === 'string' && value.trim().length === 0) return false;
  return !Number.isNaN(Number(value));
};

/**
 *
 * Return a proper `number` or 0 if NaN, "", "   ", null, undefined.
 * Considers numeric strings.
 * supports optional truncate flag.
 */
export const normalizeToNumberOrZero = (value: any, truncate = true) => {
  try {
    const num = Number(value);
    if (Number.isNaN(num)) return 0;
    if (truncate) return Math.trunc(num);
    return num;
  } catch (e) {
    return 0;
  }
};

export function wait(ms: number): Promise<undefined> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });
}

export function filterObject<T extends Record<string, any>>(
  obj: T,
  keys: string[],
  ignoreCaps = true,
): Partial<T> {
  if (!obj) {
    return {};
  }
  return keys.reduce((filteredObj, key) => {
    const keyLower = key.toLowerCase();
    const objKey = ignoreCaps
      ? Object.keys(obj).find((k) => k.toLowerCase() === keyLower)
      : key;

    if (objKey && objKey in obj) {
      // @ts-ignore
      filteredObj[objKey] = obj[objKey];
    }
    return filteredObj;
  }, {} as Partial<T>);
}

export function convertPhoneNumber(phoneNumber: string): string {
  // Check if the phone number starts with a plus sign and remove it
  if (phoneNumber.startsWith('+')) {
    return phoneNumber.substring(1);
  }
  // If there's no plus sign, return the original number
  return phoneNumber;
}

export function toAbsoluteInteger(num: number) {
  return Math.abs(Math.floor(num));
}
